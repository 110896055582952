"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const material_1 = require("@mui/material");
const common_1 = require("@guildofnerds/common"); // Add AWS Amplify Auth import
const single_spa_1 = require("single-spa");
const ConfirmSignUp = ({ userEmail }) => {
    const [email, setEmail] = (0, react_1.useState)(userEmail);
    const [confirmationCode, setConfirmationCode] = (0, react_1.useState)('');
    const handleConfirmSignUp = async (e) => {
        e.preventDefault();
        try {
            if (!email || !confirmationCode) {
                alert('Please enter both email and confirmation code.');
                return;
            }
            const result = await (0, common_1.confirmSignUpCommon)({
                username: email,
                confirmationCode: confirmationCode
            });
            if (result.isSignUpComplete) {
                (0, single_spa_1.navigateToUrl)('/');
            }
        }
        catch (error) {
            return react_1.default.createElement("div", null,
                "Error logging in: ",
                error.message); // Handle error
        }
    };
    const handleResendCode = async () => {
        try {
            await (0, common_1.resendSignUpCodeCommon)({ username: email }); // Resend confirmation code
            alert('Confirmation code resent successfully!'); // Notify user
        }
        catch (error) {
            alert(`Error resending code: ${error.message}`); // Handle error
        }
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(material_1.Typography, { component: "h1", variant: "h3", align: "center" }, "Confirm Sign Up"),
        react_1.default.createElement(material_1.TextField, { variant: "outlined", margin: "normal", required: true, fullWidth: true, label: "Email", autoComplete: "email", autoFocus: true, value: email, onChange: (e) => setEmail(e.target.value) }),
        react_1.default.createElement(material_1.TextField, { variant: "outlined", margin: "normal", required: true, fullWidth: true, label: "Confirmation Code", autoComplete: "", onChange: (e) => setConfirmationCode(e.target.value) }),
        react_1.default.createElement(material_1.Button, { type: "submit", fullWidth: true, variant: "contained", color: "secondary", onClick: handleConfirmSignUp }, "Confirm"),
        email && ( // Check if email is set
        react_1.default.createElement(material_1.Typography, { variant: "body2", align: "center", onClick: handleResendCode, style: { cursor: 'pointer', color: 'blue' } }, "Resend confirmation code"))));
};
exports.default = ConfirmSignUp;
