import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import './styles.css';

let globalConfig = null;

async function loadConfig() {
  console.log('[Root Config] Starting config load');
  try {
    const response = await fetch('https://gon.zaadstra.co.uk/config.json');
    console.log('[Root Config] Config fetch response status:', response.status);
    const config = await response.json();
    console.log('[Root Config] Config loaded successfully:', config);
    return config;
  } catch (error) {
    console.error('[Root Config] Error loading config:', error);
    throw error;
  }
}

function buildRoutes() {
  const layoutData = {
    props: {
      config: globalConfig
    }
  }
  return constructRoutes(document.querySelector("#single-spa-layout"), layoutData);
}

function buildApplications(routes) {
  return constructApplications({
    routes,
    loadApp: ( app ) => {
      return import(app.name);
      // switch (app.name) {
      //   case '@guildofnerds/navbar-mfe':
      //     return import('https://mfe.gon.zaadstra.co.uk/navbar/guild-of-nerds-navbar-mfe.js');
      //   case '@guildofnerds/authentication-mfe':
      //     return import('https://mfe.gon.zaadstra.co.uk/authentication/guild-of-nerds-authentication-mfe.js');
      //   case '@guildofnerds/events-mfe':
      //     return import('https://mfe.gon.zaadstra.co.uk/events/guild-of-nerds-events-mfe.js');
      //   default:
      //     return import(app.name);
      // }
    }
  });
}

function buildLayoutEngine(routes, applications) {
  return constructLayoutEngine({ routes, applications });
}

function registerApplications(applications) {
  console.log('[Root Config] Registering applications:', applications);
  // applications.forEach(registerApplication);
  applications.forEach(app => {
    console.log(`[Root Config] Registering application: ${app.name}`);
    console.log(`[Root Config] Registering application: `, app);
    registerApplication({
      name: app.name,
      app: app.app,
      activeWhen: app.activeWhen,
      customProps: {
        config: globalConfig,
      }
    });
  });
}

export function registerApps() {
  return loadConfig()
    .then(config => {
      globalConfig = config;

      // const navBarApp = import('@guildofnerds/authentication-mfe').then(
      //   (app) => {
      //     console.log('[Root Config] NavBar app loaded:', app);
      //   }
      // );
      // console.log('[Root Config] NavBar app:', navBarApp);

      const routes = buildRoutes();
      const applications = buildApplications(routes);
      const layoutEngine = buildLayoutEngine(routes, applications);

      console.log('[Root Config] Layout engine is active:', layoutEngine.isActive());
      layoutEngine.deactivate();
      layoutEngine.activate();

      registerApplications(applications);

      layoutEngine.activate();
      start();
    })
    .catch(error => {
      console.error('[Root Config] Failed to start:', error);
      throw error;
    });
}

export function getConfig() {
  return globalConfig;
}
