"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const material_1 = require("@mui/material");
const common_1 = require("@guildofnerds/common"); // Add AWS Amplify Auth import
const single_spa_1 = require("single-spa");
const Login = () => {
    const [email, setEmail] = (0, react_1.useState)('');
    const [password, setPassword] = (0, react_1.useState)('');
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const result = await (0, common_1.signInCommon)({
                username: email,
                password: password
            });
            if (result.isSignedIn) {
                (0, single_spa_1.navigateToUrl)('/');
                return;
            }
            const signInStep = result.nextStep?.signInStep;
            if (signInStep === 'CONFIRM_SIGN_UP') {
                (0, single_spa_1.navigateToUrl)('/auth/confirm');
                return;
            }
            (0, single_spa_1.navigateToUrl)('/');
        }
        catch (error) {
            console.error('Login error:', error);
        }
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(material_1.Typography, { component: "h1", variant: "h3", align: "center" }, "Login"),
        react_1.default.createElement("form", { onSubmit: handleLogin },
            react_1.default.createElement(material_1.TextField, { variant: "outlined", margin: "normal", required: true, fullWidth: true, label: "Email", autoComplete: "email", autoFocus: true, onChange: (e) => setEmail(e.target.value) }),
            react_1.default.createElement(material_1.TextField, { variant: "outlined", margin: "normal", required: true, fullWidth: true, label: "Password", type: "password", autoComplete: "current-password", onChange: (e) => setPassword(e.target.value) }),
            react_1.default.createElement(material_1.Typography, { align: "right", sx: {
                    mt: 1,
                    mb: 2,
                    '& a': {
                        color: 'secondary.main',
                        textDecoration: 'none',
                        '&:hover': {
                            textDecoration: 'underline'
                        }
                    }
                } },
                react_1.default.createElement("a", { href: "/auth/forgot-password", onClick: (e) => {
                        e.preventDefault();
                        (0, single_spa_1.navigateToUrl)('/auth/forgot-password');
                    } }, "Forgot Password?")),
            react_1.default.createElement(material_1.Button, { type: "submit", fullWidth: true, variant: "contained", color: "secondary" }, "Login"))));
};
exports.default = Login;
