"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const material_1 = require("@mui/material"); // Import Material-UI components
const common_1 = require("@guildofnerds/common");
const single_spa_1 = require("single-spa");
const Register = () => {
    const [email, setEmail] = (0, react_1.useState)('');
    const [password, setPassword] = (0, react_1.useState)('');
    const [name, setName] = (0, react_1.useState)('');
    const [birthDate, setBirthDate] = (0, react_1.useState)('');
    const [devRoleCode, setDevRoleCode] = (0, react_1.useState)('');
    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            const userAttributes = {
                email,
                name,
                birthdate: birthDate,
            };
            // Check if feature flag is enabled and assign user group based on devRoleCode
            if (process.env.ENABLE_USER_GROUP_ASSIGNMENT) {
                userAttributes['custom:devRoleCode'] = devRoleCode; // Add custom attribute
            }
            const result = await (0, common_1.signUpCommon)({
                username: email,
                password,
                options: {
                    userAttributes,
                },
            });
            if (result.isSignUpComplete) {
                (0, single_spa_1.navigateToUrl)('/');
            }
            if (result.nextStep?.signUpStep === 'CONFIRM_SIGN_UP') {
                (0, single_spa_1.navigateToUrl)('/auth/confirm');
            }
        }
        catch (error) {
            console.error('Error registering:', error);
        }
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(material_1.Typography, { component: "h1", variant: "h3", align: "center" }, "Register"),
        react_1.default.createElement("form", { onSubmit: handleRegister },
            react_1.default.createElement(material_1.TextField, { variant: "outlined", margin: "normal", required: true, fullWidth: true, label: "Email", autoComplete: "email", autoFocus: true, onChange: (e) => setEmail(e.target.value) }),
            react_1.default.createElement(material_1.TextField, { variant: "outlined", margin: "normal", required: true, fullWidth: true, label: "Password", type: "password", autoComplete: "current-password", onChange: (e) => setPassword(e.target.value) }),
            react_1.default.createElement(material_1.TextField, { variant: "outlined", margin: "normal", required: true, fullWidth: true, label: "Name", onChange: (e) => setName(e.target.value) }),
            react_1.default.createElement(material_1.TextField, { variant: "outlined", margin: "normal", required: true, fullWidth: true, label: "Birth Date", type: "date", InputLabelProps: {
                    shrink: true,
                }, onChange: (e) => setBirthDate(e.target.value) }),
            process.env.ENABLE_USER_GROUP_ASSIGNMENT && (react_1.default.createElement(material_1.TextField, { variant: "outlined", margin: "normal", required: false, fullWidth: true, label: "Dev Role Code", onChange: (e) => setDevRoleCode(e.target.value) })),
            react_1.default.createElement(material_1.Button, { type: "submit", fullWidth: true, variant: "contained", color: "secondary" }, "Register"))));
};
exports.default = Register;
